import React from 'react';
import AudioPlayer from './audioPlayer'; // Importiere die AudioPlayer-Komponente

function pushYourteam() {
    return (
        <>
            <div className="mx-auto w-full max-w-sm m-2 p-2 bg-gradient-to-r from-gray-900 via-purple-500 to-purple-800 rounded-lg">
                <h5 className="mb-3 text-gray-400 text-center text-2xl dark:text-white">Push Your Team</h5>
                <ul className="my-4 space-y-3">

                    <li>
                        <button className="rounded-full bg-green-500  text-white font-bold w-20 h-20">
                            <AudioPlayer audioSrc="/audio/piuw.mp3" />
                        </button>
                        <span className="flex-1 ms-3 text-2xl text-gray-300 whitespace-nowrap">PIUW</span>

                    </li>
                    <li>
                        <button className="rounded-full bg-green-500  text-white font-bold w-20 h-20">
                            <AudioPlayer audioSrc="/audio/oh-yeah.mp3" />
                        </button>
                        <span className="flex-1 ms-3 text-2xl text-gray-300 whitespace-nowrap">oh yeah</span>
                    </li>
                    <li>
                        <button className="rounded-full bg-green-500  text-white font-bold w-20 h-20">
                            <AudioPlayer audioSrc="/audio/yes-2.mp3" />
                        </button>
                        <span className="flex-1 ms-3 text-2xl text-gray-300 whitespace-nowrap">Yes 2</span>
                    </li>
                    <li>
                        <button className="rounded-full bg-green-500  text-white font-bold w-20 h-20">
                            <AudioPlayer audioSrc="/audio/yippee.mp3" />
                        </button>
                        <span className="flex-1 ms-3 text-2xl text-gray-300 whitespace-nowrap">YIPPEE</span>
                    </li>

                </ul>
                <div>
                    <h5 className="mb-3 text-base font-semibold text-gray-900 md:text-xl dark:text-white">
                        Have Fun! play hard - win fast
                    </h5>
                </div>
            </div>
        </>
    );
}

export default pushYourteam;
