import React from 'react';
import AudioPlayer from './audioPlayer'; // Importiere die AudioPlayer-Komponente

function beatYourEnemy() {
    return (
        <>
            <div className="mx-auto w-full max-w-sm m-2 p-2 bg-gradient-to-r from-gray-900 via-purple-500 to-purple-800 rounded-lg">
                <h5 className="mb-3 text-base font-semibold text-gray-900 md:text-xl dark:text-white">
                    Macht sie alle! Schlagt sie aus der Halle!
                </h5>
                <ul className="my-4 space-y-3">
                    <li>
                        <button className="rounded-full bg-pink-600  text-white font-bold w-20 h-20">
                            <AudioPlayer audioSrc="/audio/kururinnn.mp3" />
                        </button>
                        <span className="flex-1 ms-3 text-2xl text-gray-300 whitespace-nowrap">kururinnn</span>

                    </li>
                    <li>
                        <button className="rounded-full bg-pink-600  text-white font-bold w-20 h-20">
                            <AudioPlayer audioSrc="/audio/downer.mp3" />
                        </button>
                        <span className="flex-1 ms-3 text-2xl text-gray-300 whitespace-nowrap">DOWNER</span>
                    </li>
                    <li>
                        <button className="rounded-full bg-pink-600 text-white font-bold w-20 h-20">
                            <AudioPlayer audioSrc="/audio/zonk.mp3" />
                        </button>
                        <span className="flex-1 ms-3 text-2xl text-gray-300 whitespace-nowrap">ZONK</span>
                    </li>
                    <li>
                        <button className="rounded-full bg-pink-600  text-white font-bold w-20 h-20">
                            <AudioPlayer audioSrc="/audio/airhorn.mp3" />
                        </button>
                        <span className="flex-1 ms-3 text-2xl text-gray-300 whitespace-nowrap">AIRHORN</span>
                    </li>
                    <li>
                        <button className="rounded-full bg-pink-600  text-white font-bold w-20 h-20">
                            <AudioPlayer audioSrc="/audio/jabba-2.mp3" />
                        </button>
                        <span className="flex-1 ms-3 text-2xl text-gray-300 whitespace-nowrap">Jabba-2</span>
                    </li>
                    <li>
                        <button className="rounded-full bg-pink-600  text-white font-bold w-20 h-20">
                            <AudioPlayer audioSrc="/audio/was-ist-denn-hier-los.mp3" />
                        </button>
                        <span className="flex-1 ms-3 text-2xl text-gray-300 whitespace-nowrap">Was ist denn hier los?</span>
                    </li>
                    <li>
                        <button className="rounded-full bg-pink-600  text-white font-bold w-20 h-20">
                            <AudioPlayer audioSrc="/audio/yea.mp3" />
                        </button>
                        <span className="flex-1 ms-3 text-2xl text-gray-300 whitespace-nowrap">Yea Wow</span>
                    </li>
                </ul>
                <div>
                    <h5 className="mb-3 text-base font-semibold text-gray-900 md:text-xl dark:text-white">
                        Have Fun! play hard - win fast
                    </h5>
                </div>
            </div>

        </>
    );
}

export default beatYourEnemy;
