import React from 'react';
import Navbar from './components/Navbar';
import Pushyourteam from './components/Pushyourteam';
import BeatYourEnemy from './components/BeatYourEnemy';
import '@fortawesome/fontawesome-free/css/all.css'; // Importiere Font Awesome
import Footer from './components/Footer';



class App extends React.Component {
  render() {
    return (
      <div className='bg-gradient-to-r from-gray-800 via-gray-500 to-purple-900'>
        <Navbar />
        <Pushyourteam />
        <BeatYourEnemy />
        <Footer />
      </div>

    );
  }
}

export default App;
