import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons'; // Importiere das Icon

class AudioPlayer extends React.Component {
    constructor(props) {
        super(props);
        this.audioRef = React.createRef(); // Erstelle eine Ref für das <audio>-Element
    }

    playAudio = () => {
        this.audioRef.current.play(); // Rufe die play()-Methode des <audio>-Elements auf
    }

    render() {
        return (
            <div>
                {/* Hier dein Audioplayer-Code */}
                <div onClick={this.playAudio}>
                    <FontAwesomeIcon icon={faPlayCircle} beat size="2x" />

                </div>
                <audio ref={this.audioRef} src={this.props.audioSrc} />
            </div>
        );
    }
}

export default AudioPlayer;
